.preTitleBanner{
    margin-top: 1rem;
    padding-bottom: 2rem !important;
    background-color: #00000096;
    color: white;
}

.preTitleBanner .commonCardBodyButton{
    margin-top: -4.0rem;
    width: 35%;
    margin-right: -0.8rem;
    height: 3.8rem !important;
    border-radius: 1.3rem !important;
}

.preTitleBanner .cardContent {
    padding-bottom: 0;
    width: 50%;
    overflow: visible;
    margin-top: 2rem;
    background-color: transparent !important;
    font-weight: bold;
}

.preTitleBanner .informationCardInformationName.preTitleBannerInfo {
    background-color: transparent !important;
    color: white !important;
}

.preTitleBannerInfo {
    color: white !important;
    margin-left: 1rem;
}

.preTitleBannerInfoValue {
    color: white !important;
}
.preTitleBanner .titleButtonText {
    margin: 0;
    font-weight: normal;
}
.preTitleBanner .titleButtonIcon {
    margin: 0;
}


/*...*/

.preTitleBanner.pending{
    margin-top: 1rem;
    padding-bottom: 2rem !important;
    background-color: #00000096;
    color: var(--yellow-color);
}

.preTitleBanner.pending .commonCardBodyButton{
    margin-top: -4.0rem;
    width: 35%;
    margin-right: -0.8rem;
    background-color: white;

}

.pending .preTitleBannerInfoValue {
    color: var(--yellow-color) !important;
}

.preTitleBanner.pending .titleButtonText {
    color: #5E4E15 !important;
    font-size: 1.0rem !important;
}

.preTitleBanner.pending .titleButtonIcon {
    color: #5E4E15 !important;
    font-size: 2.2rem !important;
}

.preTitleBanner.pending .cardContent {
    padding-bottom: 0;
    width: 50%;
    overflow: visible;
    margin-top: 2rem;
    background-color: transparent !important;
    font-weight: bold;
}

.preTitleBanner.pending .informationCardInformationName.preTitleBannerInfo {
    background-color: transparent !important;
    color: black !important;
}

.preTitleBanner.pending .informationCardInformationName.preTitleBannerInfo {
    background-color: transparent !important;
    color: var(--yellow-color) !important;
}

.preTitleBanner.pending .preTitleBannerInfo {
    color: black !important;
    margin-left: 1rem;
}


/*...*/

.preTitleBanner.alerted{
    margin-top: 1rem;
    padding-bottom: 2rem !important;
    background-color: #00000096;
    color: white;
}

.preTitleBanner.alerted .commonCardBodyButton{
    margin-top: -4.0rem;
    width: 35%;
    margin-right: -0.8rem;
    background-color: white;
}

.preTitleBanner.alerted .cardContent {
    padding-bottom: 0;
    width: 50%;
    overflow: visible;
    margin-top: 2rem;
    background-color: transparent !important;
    font-weight: bold;
}

.preTitleBanner.alerted .informationCardInformationName.preTitleBannerInfo {
    background-color: transparent !important;
    color: white !important;
}

.preTitleBanner.alerted .informationCardInformationName.preTitleBannerInfo {
    background-color: transparent !important;
    color: white !important;
}

.preTitleBanner.alerted .preTitleBannerInfo {
    color: white !important;
    margin-left: 1rem;
}

 .preTitleBanner.alerted .titleButtonIcon {
    color: var(--red-color) !important;
    font-size: 2.2rem !important;
}

.preTitleBanner.alerted .titleButtonText {
    color: var(--red-color) !important;
    font-size: 1.0rem !important;
}