.informationCardInformationName {
    font: var(--regular-font) !important;
    color: var(--washed-out-font-color) !important;
    font-size: var(--title-font-size) !important;
}

.informationCardInformationValue {
    margin-left: 0.3rem;
    font-size: var(--title-font-size) !important;
}

