html, body, #root, .App, .conteiner-fluid, .fill {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.default-color {
  background-color: var(--default-background-color);
}

.modal-body > div, .modal-body {
  margin: 0 !important;
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.big-part {
  overflow-y: auto;
  backdrop-filter: blur(5px);
  background-color: rgba(0,0,0,.2);
}

.big-part::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.7);
	background-color: var(--default-background-color);
}

.big-part::-webkit-scrollbar
{
	width: 10px;
	background-color: var(--bright-background-color);
}

.big-part::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--popup-background-color);
}

.loader_comp, .loader_comp * {
  z-index: 1999999 !important;
  opacity: .98 !important;
}

.big-part .left-sibling {
  padding-right: 0.65rem !important;
}

.big-part .right-sibling {
  padding-left: 0.65rem !important;
}


.leaflet-container {
  background: var(--default-background-color) !important;
}

.devicePopup.col-md-4{
  padding: 0;
}