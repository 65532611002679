.chartWithInfoCarousel > .commonCard .commonCard {
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0px 0px 0px 0px rgba(240, 217, 217, 0.15) !important;
}




.chartCarouselHeaders {
    padding-top: 1rem;
}

.chartCarouselFirstHeader {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.chartCarouselSecondHeader {
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.chartCarouselThirdHeader {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #4F4F4F
}

.chartWithInfoCarouselContent {
    margin-top: 2.5rem !important;
}

.chartWithInfoCarouselContent.hasChart{
    margin-top: -2rem !important;
}



