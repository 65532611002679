.commonCard {
    background-color: var(--bright-background-color);
    color: var(--default-font-color);
    width: 100%;
    flex: 1;
    border-radius: var(--default-border-radius);
    box-shadow: var(--default-box-shadow);
    height: auto;
    overflow-x: hidden;
}

.commonCard.hasBodyButton {
    padding-bottom: 3rem;
}

.commonCardTitleHolder {
    display: flex;
}

.commonCard.alerted, .commonCard.alerted .informationCardInformationName {
    background-color: var(--red-color);
    color: white !important
}

.commonCard.pending, .commonCard.pending .informationCardInformationName {
    background-color: var(--yellow-color);
    color: black !important
}

.commonCard.ok, .commonCard.ok .informationCardInformationName {
    background-color: var(--green-color);
    color: white !important
}


.titleButton {
    background-color: white;
    border-radius: var(--default-border-radius);
    height: 2.6875rem;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .4rem;
    right: 3rem;
    position: absolute;
    opacity: 0.95;
    cursor: pointer;
    margin-right: -1rem;
}


.commonCardBodyButton {
    background-color: #9F3232;
    border-radius: 0.3rem;
    height: 2.24rem;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.9rem;
    right: 3rem;
    opacity: 0.95;
    cursor: pointer;
    margin-right: -1rem;
    position: absolute;
}

.titleButton:hover {
    opacity: 0.98
}

.titleButton.clicked,  .titleButton.clicked:hover{
    opacity: 1;
    border-width: 2px;
    border-color: white;
    cursor: default;
}

.titleButtonText {
    width: 70%;
    text-align: center;
}

.commonCard.alerted .titleButtonIcon, .commonCard.alerted .titleButton
{
    color: var(--red-color) !important;
}


.commonCard.pending .titleButtonIcon, .commonCard.pending .titleButton
{
    color: var(--yellow-color) !important;
}

.commonCard.ok .titleButtonIcon, .commonCard.alerted .titleButton
{
    color: var(--default-font-color) !important;
}


.commonCard.copy .titleButtonText {
    color: #fff !important;
}

.commonCard.copy .titleButtonIcon  {
    color: #fff !important;
    margin-right: 0.8rem;
    margin-left: -0.8rem;
}

.commonCard.copy .titleButton {
    background-color: #111111;
    margin-top: .7rem;
    width: 18%;
    border-radius: 0.3rem;
    height: 1.7rem;
    font-size: 0.75rem;
}

.commonCard.copy .commonCardBodyButton {
    border-radius: 0.3rem;
    height: 2.24rem;
}

.commonCardBodyButton .titleButtonText {
    margin-right: 0.3rem;
    margin-left: 0.5rem;
}


.commonCardTitle {
    font-weight: 600 !important;
    font: var(--title-font);
    font-size: var(--title-font-size);
    width: 50%;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 1rem;
    padding-bottom: 0.7rem;
    min-height: 1rem;
    display: flex;
    align-items: center;
}

.popup .commonCardTitle {
    padding-top: 2rem;
    padding-left: 2rem;
}

.popup .cardContent {
    padding-left: 2rem;
    padding-right: 2rem;
}

.popup .titleButton {
    margin-top: 2rem !important;
}

.cardContent {
    flex: 1;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-bottom: 1rem;
    padding-top: 0;
    font: var(--regular-font);
    font-size: var(--regular-font-size);
    overflow-y: hidden;
    background-color: inherit !important;
}



.cardContentCarouselDotList > li > button {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: .3rem;
    margin-right: .3rem;
    border: 0;
    opacity: 0.7;
}


.subCard {
    margin-bottom: 0.7rem;
}


.commonCard .rah-static, .commonCard .rah-static--height-auto, 
.commonCard .rah-static>div, .commonCard .rah-static--height-auto>div {
    background-color: inherit;
}