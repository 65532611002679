
.status-indicator {
    border-radius: var(--default-border-radius);
}

.green-status {
    background-color: var(--green-color);
}

.yellow-status {
    background-color: var(--yellow-color);
}

.red-status {
    background-color: var(--red-color);
}

.blue-status {
    background-color: var(--blue-color);
}

.inactive-status {
    background-color: var(--inactive-color);
}


.no-padding {
    padding: 0 !important;
  }
