:root {  /*change this to change style*/
  font-family: 16px;

  --default-background-color: #B5CBB7;
  --popup-background-color: #818479;
  --bright-background-color: #FFF;
  --green-color: #04A777;
    --green-color-rgb-values: 238, 186, 0;
  --red-color: #FB3640;
    --red-color-rgb-values: 251, 54, 64;
  --yellow-color: #EEBA00;
    --yellow-color-rgb-values: 238, 186, 0;
  --blue-color: #0A2463;
  --dark-color: #292C22;
  --inactive-color: #818479;

  --marker-green:#04a70f;

  
  --washed-out-font-color: rgba(41, 44, 34, 0.50);
  --default-font-color: #292C22;

  --default-font-family: "Inter";

  --title-font-size: 0.9rem;
  --title-font-style: normal;
  --title-font-weight: 900;
  --title-line-height: normal;
  
  --title-font:
    var(--title-font-style)
    var(--title-font-weight)
    var(--title-font-size)
    var(--title-line-height)
    var(--default-font-family);

  --regular-font-size: 0.75rem;
  --regular-font-style: normal;
  --regular-font-weight: 400;
  --regular-line-height: normal;
  
  --regular-font:
    var(--regular-font-style)
    var(--regular-font-weight)
    var(--regular-font-size)
    var(--regular-line-height)
    var(--default-font-family);

  --small-font-size: 0.625rem;
  --small-font-style: normal;
  --small-font-weight: 400;
  --small-line-height: normal;
    
  --small-font:
    var(--small-font-style)
    var(--small-font-weight)
    var(--small-font-size)
    var(--small-line-height)
    var(--default-font-family);


  --default-border-radius: 1.25rem;

  --default-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);


  --scroll-background-color: var(--popup-background-color);
  --scroll-tumb-color: var(--dark-color);
  --scroll-tumb-border-color: var(var(--inactive-color));
}



html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--default-font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body * {
  font-family: var(--default-font-family);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

path.leaflet-interactive:focus {
  outline: none;
}




.hidden_button {
  display:block;
  position: absolute;
  height: 1.75rem;
  width: 3rem;
  z-index: 2023050230;
  background-color: transparent;
}

.hidden_button.topright {
  right: 0;
  top: 0;
  cursor: pointer;
}

.hidden_button.botleft {
  left: 0;
  bottom: 0;
}


.leaflet-top{
  bottom: 0px !important;
  position: absolute !important;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
  bottom: 1rem !important;
  position: absolute !important;
}