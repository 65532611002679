.table {
    width: 100%;
    margin-bottom: 1rem;
	font: var(--small-font);
	font-size: var(--small-font-size);
	background-color: inherit;
}

.table-carrier {
    width: 100%;
    overflow-y: auto;
	overflow-x: hidden;
    border-radius: var(--default-border-radius);
	background-color: inherit;
}

.table-carrier::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: var(--bright-background-color);
}

.table-carrier::-webkit-scrollbar
{
	width: 3px;
	background-color: var(--bright-background-color);
}

.table-carrier::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--default-background-color);
}

.table thead th, .table thead {
	position: sticky;
	top:0;
	background-color: inherit !important;
	z-index: 2;
	margin-bottom: 3rem;
	border-bottom-width: 0.15rem !important;
	border-bottom-color: #00000026 !important;
}

table {
	border-collapse: separate !important;
  	border-spacing: 0 !important;
}

.table thead th {
	padding-bottom: 0.15rem !important;
	margin-bottom: 2px !important;
}

.table td {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	background-color: inherit !important;
}


.table tr:first-child td span{
	margin-top: 0.25rem !important;
	display: flex;
}

.table tbody, .table tr, .table th {
	background-color: inherit;
}

.loading {
    text-align: center;
    color: var(--washed-out-font-color);
	background-color: inherit;
}

.cell-bearer { /*after creation width is being added programatically with sizeme lib.*/
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
	background-color: inherit;
}

td:last-child {
	max-width: 120px;
	padding-right: 5rem;
}

.tooltip {
	border-radius: var(--default-border-radius) !important;
	background-color: var(--popup-background-color) !important;
	color: "black";
	opacity: 1 !important;
	background-color: inherit;
}

.panicButtonList td:not(:first-child), .panicButtonList th {
	color: white !important;
}

.panicButtonList td:first-child .cell-bearer {
	overflow: visible !important;
}


.panicButtonList.pending td:not(:first-child), .panicButtonList.pending th {
	color: black !important;
}


.panicButtonList .signalYes span{
	border-radius: 0.35rem;
	width: 1.75rem !important;
	justify-content: center;
	display: flex;
	background-color: #03F0AA !important;
	color: #005F43 !important;
}


.panicButtonList .signalNo span{
	border-radius: 0.35rem;
	width: 1.75rem !important;
	justify-content: center;
	display: flex;
	background-color: #00000096;
}


.panicButtonList .table tr td {
	padding-bottom: 0.15rem !important;
}