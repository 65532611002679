.devicePopup {
    bottom: 0px;
    right: 0px;
    background-color: var(--bright-background-color);
    color: var(--default-font-color);
    border-top-left-radius: var(--default-border-radius);
    border-top-right-radius: var(--default-border-radius);
    box-shadow: var(--default-box-shadow);
    position: absolute;
    z-index: 1999998;
}


.devicePopupTitleHolder {
    padding-left: 2rem;
    padding-top: 2rem;
    font-size: 1.3rem;
    display: flex;
}


.devicePopupCardTitle {
    width: 50%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.7rem;
    min-height: 1rem;
    display: flex;
    align-items: center;
}

.devicePopupTitleButton {
    font-size: 1.2rem;
    background-color: #111111;
    color: #FFFFFF;
    border-radius: 0.5rem;
    height: 2.6875rem;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .4rem;
    right: 3rem;
    position: absolute;
    opacity: 0.95;
    cursor: pointer;
    margin-right: -1rem;
}